<template>
  <div>
    <TabHeaderFilter
      :placeholder="placeholder"
      :filter="filter"
      @exportExcel="exportExcel"
      @sidebarFilter="sidebarFilter"
      @handleSearch="handleSearch"
    />
    <TableCustomerList
      :fields="fields"
      :items="items"
      :isBusy="isBusy"
      :filter="filter"
      :rows="rows"
      :pageOptions="pageOptions"
      :showing="showing"
      :showingTo="showingTo"
      @getPage="getPage"
      @getPageOption="getPageOption"
      :fieldsDetails="fieldsDetails"
      @getCustomerDetailsList="getCustomerDetailsList"
    />
  </div>
</template>

<script>
import TabHeaderFilter from "@/components/friendGetFriend/detail/customer/TabHeaderFilter";
import TableCustomerList from "@/components/friendGetFriend/detail/customer/TableCustomerList";
export default {
  components: {
    TabHeaderFilter,
    TableCustomerList,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    fields: {
      required: true,
      type: Array,
    },
    fieldsDetails: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showing:{
      required: true,
      type: Number
    },
    showingTo:{
      required: true,
      type: Number
    },
    placeholder:{
      required:false,
      type:String
    }
  },
  methods:{
    exportExcel(){
      this.$emit("exportExcel");
    },
    getPage(value){
      this.$emit("getPage",value);
    },  
    getPageOption(value){
      this.$emit("getPageOption",value);
    },
    sidebarFilter(){
      this.$emit("sidebarFilter");
    },
    handleSearch(value){
      this.$emit("handleSearch",value);
    },
    getCustomerDetailsList(row, index){
      this.$emit("getCustomerDetailsList", row, index);
    }
  }
};
</script>

<style lanng="scss" scoped>
</style>
