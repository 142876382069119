<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">FRIEND GET FRIEND DETAIL</h1>
        </div>
        <b-tabs class="mt-3">
          <b-tab title="Detail">
            <div class="p-3 bg-white">
              <FormPanel
                :form="form"
                :v="$v"
                :isDisable="isDisable"
                :id="parseInt(id)"
                :privilegeCodeList="list.privilegeCode"
                :conditionList="list.condition"
                :rewardTypeList="list.rewardType"
                :segmentList="list.segment"
                @selectFriendPrivilegeCode="selectFriendPrivilegeCode"
              />
            </div>
            <div class="no-gutters bg-white mt-3 py-2 px-3">
              <b-form-checkbox
                switch
                v-model="form.status"
                class="radio-active"
                size="lg"
                :value="1"
                :unchecked-value="0"
              >
                <span class="ml-2 main-label">{{
                  form.status ? "Active" : "Inactive"
                }}</span>
              </b-form-checkbox>
            </div>

            <FooterAction @submit="checkForm(0)" routePath="/friendgetfriend" />
          </b-tab>
          <b-tab title="Description">
            <div class="bg-white">
              <DescriptionPanel
                :form="form"
                :v="$v"
                :isDisable="isDisable"
                :id="parseInt(id)"
              />
            </div>
            <FooterAction @submit="checkForm(0)" routePath="/friendgetfriend" />
          </b-tab>
          <b-tab title="Content">
            <div class="bg-white">
              <ContentPanel
                :form="form"
                :v="$v"
                :isDisable="isDisable"
                :id="parseInt(id)"
                :privilegeCode="privilegeCode"
              />
            </div>
            <FooterAction @submit="checkForm(0)" routePath="/friendgetfriend" />
          </b-tab>
          <b-tab title="Customer List" v-if="parseInt(id) > 0">
            <TabCustomerList
              placeholder="Search Name"
              :filter="filterCustomer"
              :fields="fieldsCustomer"
              :items="itemsCustomer"
              :isBusy="isBusyCustomer"
              :rows="rowsCustomerList"
              @exportExcel="exportExcel"
              :pageOptions="pageOptions"
              :showing="showing"
              :showingTo="showingTo"
              @getPage="getPage"
              @getPageOption="getPageOption"
              @sidebarFilter="sidebarFilter"
              @handleSearch="handleSearch"
              :fieldsDetails="fieldsCustomerSub"
              @getCustomerDetailsList="getCustomerDetailsList"
            />
          </b-tab>
        </b-tabs>
      </b-form>
    </div>
    <SidebarFilterCustomerList
      ref="sidebarFilterCustomerList"
      :filter="filterCustomer"
      @searchFilter="searchFilter"
    />
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import FormPanel from "@/components/friendGetFriend/detail/FormPanel";
import DescriptionPanel from "@/components/friendGetFriend/detail/DescriptionPanel";
import ContentPanel from "@/components/friendGetFriend/detail/content/ContentPanel";
import TabCustomerList from "@/components/friendGetFriend/detail/customer/TabCustomerList";
import SidebarFilterCustomerList from "@/components/friendGetFriend/detail/customer/SidebarFilterCustomerList";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  name: "FriendGetFriendDetails",
  components: {
    FormPanel,
    DescriptionPanel,
    ContentPanel,
    TabCustomerList,
    SidebarFilterCustomerList,
    OtherLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      isBusy: false,
      isDisable: false,
      form: {
        id: 1,
        name: "",
        url_key: "",
        reward_point: 0,
        reward_privilege: 0,
        limit: 0,
        reward_type_id: 1,
        reward_friend_condition_type_id: 0,
        reward_friend_point: 0,
        reward_friend_privilege: 0,

        status: 1,
        valid_from: "",
        valid_to: "",
        invitation_message: "",
        card_message: "",
        banner: "",
        background_page: "",
        share_label: "",
        share_label_image: "",
        coupon_label: "",
        coupon_label_image: "",
        gift_label: "",
        user_invited: 0,
        segment_id: 0,
        image_card: [],
      },
      list: {
        privilegeCode: [{ id: 0, name: "Please Select Privilege Code" }],
        condition: [{ id: 0, name: "Please Select Condition" }],
        rewardType: [{ id: 1, name: "All" }],
        segment: [],
      },
      filterCustomer: {
        search: null,
        take: 10,
        page: 1,
      },
      fieldsCustomer: [
        {
          label: "",
          key: "toggle",
          class: "w-toggle",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Tier",
          key: "tier",
        },
        {
          label: "New Friends",
          key: "new_friend_count",
        },
        {
          label: "Register Date",
          key: "register_date",
        },
        // {
        //   label: "",
        //   key: "total_spend",
        // },
      ],
      itemsCustomer: [],
      isBusyCustomer: true,
      rowsCustomerList: 0,
      filterExport: {
        register_date: null,
        search: null,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showing: 1,
      showingTo: 10,
      result: 0,
      fieldsCustomerSub: [
        {
          label: "Friends Name",
          key: "name",
        },
        {
          label: "Member ID",
          key: "member_id",
        },
        {
          label: "Telephone",
          key: "telephone",
        },
        {
          label: "Register Date",
          key: "register_date",
        },
        {
          label: "Total Spend",
          key: "total_spend",
        },
      ],
      privilegeCode: {
        id: 0,
        name: "",
      },
    };
  },
  validations: {
    form: {
      name: { required },
      url_key: { required },
      reward_type_id: { required, minValue: minValue(1) },
      reward_point: { required, minValue: minValue(0) },
      reward_privilege: {},
      limit: { required, minValue: minValue(0) },
      reward_friend_condition_type_id: { required, minValueMain: minValue(1) },
      reward_friend_point: { required, minValue: minValue(0) },
      reward_friend_privilege: {},
      valid_from: { required },
      valid_to: { required },
      banner: { required },
      invitation_message: { required },
      card_message: { required },
      image_card: { required },
      background_page: { required },
      share_label: { required },
      share_label_image: { required },
      coupon_label: { required },
      coupon_label_image: { required },
      gift_label: { required },
      segment_id: { required, minValue: minValue(0) },
    },
  },
  watch: {},
  created: async function () {
    await this.getOption();

    if (this.id !== "0") {
      await this.getData();
      await this.getCustomerList();
    }
    this.$v.$reset();
  },
  destroyed() {},
  methods: {
    getData: async function () {
      this.isLoading = true;

      await this.$store.dispatch("getFriendGetFriendDetail", this.id);
      var data = await this.$store.state.friendgetfriend
        .stateFriendGetFriendDetail;

      this.form = data.detail;
      if (this.form.reward_friend_privilege)
        await this.getPrivilegeDeails(this.form.reward_friend_privilege);

      this.isLoading = false;
    },
    getPrivilegeDeails: async function (id) {
      await this.$store.dispatch("ActionGetPrivilegeCodeByID", id);
      var data = await this.$store.state.modulePrivilegeCode
        .stateGetPrivilegeCodeByID;
      if (data.result == 1) {
        this.privilegeCode = data.detail.detail;
      }
    },
    selectFriendPrivilegeCode: async function (val) {
      this.getPrivilegeDeails(val);
    },
    async getOption() {
      return await Promise.all([
        this.getRewardTypeList(),
        this.getRewardfreindConditionTypeList(),
        this.getPrivilegeCode(),
        this.getSegmentList(),
      ]);
    },
    getSegmentList: async function () {
      let filter = {
        search: "",
        page: 1,
        take: 9999,
      };
      await this.$store.dispatch("getSegmentList", filter);
      var data = await this.$store.state.segment.segmentList;
      if (data.result == 1) {
        this.list.segment = data.detail.data;
      }
    },
    async getCustomerList() {
      this.isBusyCustomer = true;
      this.filterCustomer.id = parseInt(this.$route.params.id);
      this.filterCustomer.register_date = !this.filterCustomer.register_date
        ? "0001-01-01"
        : this.filterCustomer.register_date;
      await this.$store.dispatch(
        "getCustomerFriendGetFriendList",
        this.filterCustomer
      );
      const data = this.$store.state.friendgetfriend.customerList;
      this.rowsCustomerList = data.detail.count;
      let itemsCustomer = [];
      if (this.rowsCustomerList > 0) {
        await data.detail.result.forEach((item) => {
          itemsCustomer.push({
            ...item,
            _showDetails: false,
            details: {
              items: [],
              isBusy: true,
              rows: 0,
              filter: {
                take: 10,
                page: 1,
              },
            },
          });
        });
      }
      this.itemsCustomer = itemsCustomer;
      this.isBusyCustomer = false;
    },
    async getCustomerDetailsList(row, index) {
      let details = row.item.details;
      let body = {
        ...details.filter,
        id: parseInt(this.$route.params.id),
        user_guid: row.item.user_guid,
      };
      details.isBusy = true;
      await this.$store.dispatch("getCustomerDetailsFriendGetFriendList", body);
      const data = this.$store.state.friendgetfriend.customerDetailsList;
      details.rows = data.detail.count;
      if (details.rows > 0) {
        details.items = data.detail.result;
      } else {
        details.items = [];
      }
      details.isBusy = false;
      this.itemsCustomer[index].details = details;
    },
    async getRewardTypeList() {
      await this.$store.dispatch("getRewardTypeFriendGetFriend");
      const data = this.$store.state.friendgetfriend.rewardList;
      if (data.result == 1) {
        this.list.rewardType = data.detail;
      }
    },
    async getRewardfreindConditionTypeList() {
      await this.$store.dispatch("getConditionFriendGetFriend");
      const data = this.$store.state.friendgetfriend.conditionList;
      if (data.result == 1) {
        this.list.condition = data.detail;
      }
    },
    async exportExcel() {
      this.$bus.$emit("showLoading");
      this.filterExport.search = this.filterCustomer.search;
      this.filterExport.id = parseInt(this.$route.params.id);
      this.filterExport.register_date =
        this.filterCustomer.register_date == ""
          ? "0001-01-01"
          : this.filterCustomer.register_date;

      await this.$store.dispatch("exportCustomer", this.filterExport);
      const data = this.$store.state.friendgetfriend.stateExportCustomer;
      this.$bus.$emit("hideLoading");
      if (data.status == 200) {
        this.confirmAlert({
          message: "Do you want to download the file now?",
          title: "Success !",
          icon: "success",
          confirmButtonText: "Go To Page",
          cancelButtonText: "Close",
        }).then((val) => {
          if (val.isConfirmed) {
            // this.deleteItem(id);
            const routeData = this.$router.resolve({
              name: "Customer Report List",
            });
            window.open(routeData.href, "_blank");
          }
        });
      } else {
        this.errorAlert("Export fail");
      }
    },
    getPage(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    getPageOption(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    sidebarFilter() {
      this.$refs.sidebarFilterCustomerList.show();
    },
    searchFilter(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    handleSearch(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    async checkForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$bus.$emit("showLoading");
      await this.submit();
      this.$bus.$emit("hideLoading");
    },
    async submit() {
      let id = parseInt(this.id);
      this.form.image_card = this.form.image_card.map((obj) => ({
        ...obj,
        friend_get_friend_id: id,
      }));
      let payload = this.form;
      let data = null;

      if (this.id == 0) {
        await this.$store.dispatch("createFriendGetFriendList", payload);
        data = this.$store.state.friendgetfriend.stateCreateFriendGetFriend;
      } else {
        await this.$store.dispatch("updateFriendGetFriendList", payload);
        data = this.$store.state.friendgetfriend.stateUpdateFriendGetFriend;
      }
      if (data.result == 1) {
        this.successAlert().then(() => this.$router.push("/friendgetfriend"));
      } else {
        this.errorAlert(data.message);
      }
    },
    async getPrivilegeCode() {
      const resp = await this.axios("/MarketingAutomation/getprivilege/1");
      if (resp.data.result == 1) {
        this.list.privilegeCode = resp.data.detail;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
::v-deep .w-toggle {
  width: 40px !important;
}
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: var(--theme-secondary-color);
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}
.bg-tabs-custom:active {
  background-color: var(--theme-secondary-color) !important;
}
.btn-edit {
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  font-weight: 600;
  padding: 0 !important;
}
</style>
