<template>
  <div>
    <div class="div-input">
      <div class="display-only" v-if="isDisplay"></div>
      <div :class="['input-custom', { error: isValidate }]">
        <div class="d-flex justify-content-between">
          <label v-if="textFloat" :class="labelClass">
            {{ textFloat }}
            <span v-if="isRequired" class="text-danger">*</span>
          </label>
        </div>
      </div>
    </div>
    <div class="border-preview">
      <div class="my-3 d-flex justify-content-center">
        <div class="wrap-panel">
          <div class="navbar">
            <div>
              <img
                :src="
                  $store.state.theme.themeLogo.themeLogoDomain +
                  $store.state.theme.themeLogo.result.themeLogoLine
                "
                class="logo-img"
              />
            </div>
          </div>
          <div
            class="container layout"
            v-bind:style="{ backgroundImage: 'url(' + bgImage + ')' }"
          >
            <template v-if="tabIndex == 1">
              <div class="my-auto w-100 text-center">
                <img :src="image || imgDefault" class="w-75 rounded-xl" />
              </div>
              <div class="px-5 pb-5 f-14 text-center text-color">
                {{ text }}
              </div>
            </template>
            <template v-else-if="tabIndex == 2">
              <b-card class="my-auto text-center w-75" body-class="p-2">
                <img :src="image || imgDefault" class="w-100" />
                <div class="mt-2 f-14 text-color p-2" v-if="privilegeCode.id">
                  <div class="text-bold text-left">
                    {{ privilegeCode.name }}
                  </div>
                  <div
                    class="description-page mt-2"
                    v-html="privilegeCode.description"
                  ></div>
                </div>
              </b-card>
              <div class="px-5 pb-5 f-14 text-center text-color">
                {{ text }}
              </div>
            </template>
            <template v-else-if="tabIndex == 3">
              <div class="my-auto w-100 text-center">
                <img :src="image || imgDefault" class="w-75 rounded-xl" />
              </div>
              <div class="px-5 pb-5 f-14 text-center text-color">
                {{ text }}
              </div>
            </template>
            <b-button
              variant="primary-theme"
              block
              class="w-100 py-2 mb-auto"
              type="button"
              >{{ textBtn }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgDefault from "@/assets/img/default-placeholder.png";

export default {
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    labelClass: {
      required: false,
      type: String,
      default: "",
    },
    text: {
      required: false,
      type: String,
    },
    bgImage: {
      required: false,
      type: String,
    },
    image: {
      required: false,
      type: String,
    },
    textBtn: {
      required: false,
      type: String,
    },
    tabIndex: {
      required: false,
      type: [String, Number],
    },
    imageList: {
      required: false,
      type: Array,
    },
    privilegeCode: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      imgDefault: imgDefault,
    };
  },
};
</script>

<style lang="scss" scoped>
.div-input {
  margin-bottom: 0px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}
.input-custom label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}
.input-custom textarea {
  color: var(--font-main-color);
  background-color: white;
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
}
.input-custom textarea:focus {
  border: 1px solid #16274a;
}
.input-custom.error textarea {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: 0.8rem;
  font-family: "Kanit-Light";
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
.count-characters {
  color: #9b9b9b;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 0px;
}
@media (max-width: 767.98px) {
  .input-custom label {
    font-size: 15px;
  }
}
.border-preview {
  border: 2px dashed #979797;
}
.wrap-panel {
  width: 375px;
}
.layout {
  width: 375px;
  height: 667px;
  // background-image: url(../../../../assets/img/fgf/1.png);
  background: lightgray;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: space-between; */
}
.card {
  border-radius: 0.75rem !important;
  border-color: white !important;
}
.panel-options-send {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 35px;
  background: white;
  border-radius: 1.25rem 1.25rem 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  /* align-items: center; */
  /* justify-content: center; */
  padding: 0.5rem 1rem;
  box-shadow: 0 -2px 16px -9px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.options-detail {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  color: var(--primary-color);
}
.box-link {
  border-radius: 0.5rem;
  border: 1px dashed var(--primary-color);
  padding: 0.5rem 1rem;
  background-color: var(--theme-secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.badge-count {
  padding: 0.25rem;
  font-size: 75%;
  margin-top: 0.25rem;
  width: 50px;
  text-align: center;
  height: auto;
  /* aspect-ratio: 1; */
  margin-left: 0.5rem;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  border-radius: 0.75rem;
}
.invite-cumulative {
  display: flex;
  background: var(--theme-secondary-color);
  color: var(--primary-color);
  text-align: center;
  padding: 0.5rem 0.5rem;
  border-radius: 50%;
  font-size: 2.2rem;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  max-width: 75px;
  height: auto;
  max-height: 75px;
  aspect-ratio: 1;
}
.container-invite {
  row-gap: 0.5rem;
  column-gap: 0.75rem;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.opacity {
  opacity: 0.5;
}
.navbar {
  height: 80px;
  width: 100%;
  justify-content: center;
  background-color: var(--theme-navigation-bar-color);
  transition: background-color 0.1s linear;
  box-shadow: 1px 1px 5px -3px black;
}
.logo-img {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 65px;
}
::v-deep .description-page {
  color: #5f5f5f;
  font-size: 14px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  p {
    margin-bottom: 0;
  }
}
.text-bold {
  font-weight: bold;
}
.text-color {
  color: var(--primary-color);
}
</style>
