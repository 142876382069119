<template>
  <div v-if="isLoading">
    <OtherLoading />
  </div>
  <div v-else>
    <b-row class="relative">
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          isRequired
          v-model="form.name"
          class="mb-2"
          :isValidate="v.form.name.$error"
          :v="v.form.name"
        />
      </b-col>
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Url Key"
          placeholder="Url Key"
          type="text"
          name="urlkey"
          isRequired
          :isValidate="v.form.url_key.$error"
          :v="v.form.url_key"
          v-model.trim="form.url_key"
          textValidate="Please Input URL Key"
          @onKeypress="checkURLKey($event)"
        />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <label class="main-label text-nowrap">
          Reward for Member
        </label>

        <b-form-group class="pt-2 mb-0">
          <b-form-radio-group
            id="radio-type"
            v-model="form.reward_type_id"
            :disabled="$route.params.id != 0"
          >
            <b-form-radio
              v-for="(item, index) in rewardTypeList"
              :key="index"
              :value="item.id"
              >{{ item.name }}</b-form-radio
            >
          </b-form-radio-group>
        </b-form-group>
        <span class="text-error" v-if="v.form.reward_type_id.$error"
          >Please select Reward Member Type.</span
        >
      </b-col>
    </b-row>
    <b-row v-if="form.reward_type_id == 2" class="mb-2">
      <b-col lg="6" md="6" sm="12" xs="12" class="select-customs">
        <InputSelect
          :disabled="$route.params.id != 0"
          title="Segment"
          name="segment-id"
          v-bind:options="segmentList"
          :value="form.segment_id"
          :v="v.form.segment_id"
          :isValidate="v.form.segment_id.$error"
          v-model="form.segment_id"
          valueField="id"
          textField="name"
          noPleaseSelect
        />
      </b-col>
      <!-- <b-col cols="12">
        <hr class="my-3" />
      </b-col> -->
    </b-row>
    <b-row>
      <b-col lg="6" md="6" sm="12" xs="12">
        <InputText
          textFloat="Point Reward"
          placeholder="Point Reward"
          type="number"
          name="point-reward"
          :isValidate="v.form.reward_point.$error"
          :v="v.form.reward_point"
          v-model="form.reward_point"
        />
      </b-col>
      <b-col lg="6" md="6" sm="12" xs="12" class="select-customs">
        <InputSelect
          title="Privilege Code"
          name="privilege-code"
          v-bind:options="privilegeCodeList"
          :value="form.reward_privilege"
          :v="v.form.reward_privilege"
          :isValidate="v.form.reward_privilege.$error"
          v-model="form.reward_privilege"
          valueField="id"
          textField="name"
        >
          <template v-slot:option-first>
            <b-form-select-option :value="0"
              >-- Select Privilege Code --</b-form-select-option
            >
          </template>
        </InputSelect>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="6" sm="12" xs="12">
        <InputText
          textFloat="Number of Referral Reward Per Customer (0 = Unlimit)"
          placeholder="Number of Referral Reward Per Customer (0 = Unlimit)"
          type="number"
          name="number-of-referral-reward-per-customer"
          :isValidate="v.form.limit.$error"
          :v="v.form.limit"
          v-model="form.limit"
        />
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col class="select-customs" lg="6" md="6" sm="12" xs="12">
        <label class="main-label text-nowrap mb-3">
          Reward for Friend
        </label>
        <InputSelect
          title="Condition"
          name="condition"
          v-bind:options="conditionList"
          :value="form.reward_friend_condition_type_id"
          :v="v.form.reward_friend_condition_type_id"
          :isValidate="v.form.reward_friend_condition_type_id.$error"
          v-model="form.reward_friend_condition_type_id"
          valueField="id"
          textField="name"
          textValidate="Please Select Condition."
          isRequired
        >
          <template v-slot:option-first>
            <b-form-select-option :value="0" disabled
              >-- Please Select Conditions --
            </b-form-select-option>
          </template>
        </InputSelect>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="6" sm="12" xs="12">
        <InputText
          textFloat="Point Reward"
          placeholder="Point Reward"
          type="number"
          name="point-reward"
          :isValidate="v.form.reward_friend_point.$error"
          :v="v.form.reward_friend_point"
          v-model="form.reward_friend_point"
        />
      </b-col>
      <b-col lg="6" md="6" sm="12" xs="12" class="select-customs">
        <InputSelect
          title="Privilege Code"
          name="privilege-code"
          v-bind:options="privilegeCodeList"
          :value="form.reward_friend_privilege"
          :v="v.form.reward_friend_privilege"
          :isValidate="v.form.reward_friend_privilege.$error"
          v-model="form.reward_friend_privilege"
          @onDataChange="selectFriendPrivilegeCode"
          valueField="id"
          textField="name"
        >
          <template v-slot:option-first>
            <b-form-select-option :value="0"
              >-- Select Privilege Code --</b-form-select-option
            >
          </template>
        </InputSelect>
      </b-col>
    </b-row>
    <hr class="mt-2" />
    <b-row>
      <b-col lg="6" md="6" sm="12" xs="12" class="mb-md-0 mb-3">
        <label class="label-text">
          Valid From <span class="text-danger">*</span></label
        >

        <div :class="['input-container', { error: v.form.valid_from.$error }]">
          <datetime
            type="datetime"
            v-model="form.valid_from"
            placeholder="Please Select Date"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            @input="handleMinDate"
            ref="valid_from"
          >
          </datetime>
          <div
            :class="
              'icon-primary text-right ' + id == 0 ? '' : 'cursor-default'
            "
            @click="$refs.valid_from.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="
                id == 0
                  ? 'pointer color-primary'
                  : 'cursor-default color-primary'
              "
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.valid_from.$error">
          <span class="text-error" v-if="v.form.valid_from.required == false"
            >Please select date time.</span
          >
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12" xs="12">
        <label class="label-text">
          Valid To <span class="text-danger">*</span></label
        >
        <div :class="['input-container', { error: v.form.valid_to.$error }]">
          <datetime
            type="datetime"
            v-model="form.valid_to"
            placeholder="Please Select Date"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            :min-datetime="form.valid_from"
            ref="valid_to"
          >
          </datetime>
          <div
            :class="
              'icon-primary text-right ' + id == 0 ? '' : 'cursor-default'
            "
            @click="$refs.valid_to.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="
                id == 0
                  ? 'pointer color-primary'
                  : 'cursor-default color-primary'
              "
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.valid_to.$error">
          <span class="text-error" v-if="v.form.valid_to.required == false"
            >Please select date time.</span
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelect from "@/components/inputs/InputSelect";

export default {
  components: {
    OtherLoading,
    InputSelect,
  },
  props: {
    id: {
      required: true,
      type: [String, Number],
    },
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    privilegeCodeList: {
      required: false,
      type: Array,
    },
    conditionList: {
      required: false,
      type: Array,
    },
    rewardTypeList: {
      required: false,
      type: Array,
    },
    segmentList: {
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    openModalLink() {
      this.$refs.ModalCopyUrl.show();
    },
    checkURLKey(e) {
      let regex = new RegExp("^[a-zA-Z0-9_]+$");
      let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);

      if (regex.test(str)) {
        return true;
      }

      e.preventDefault();
      return false;
    },
    handleMinDate() {
      let diff = this.$moment(this.form.valid_to).diff(
        this.$moment(this.form.valid_from),
        "min"
      );

      if (diff < 0) this.form.valid_to = "";
    },
    selectFriendPrivilegeCode(value) {
      this.$emit("selectFriendPrivilegeCode", value);
    },
  },
};
</script>

<style lang="scss" scoped>

.btn-icon {
  background-color: transparent;
  border: none;
  font-size: 16px;
}
.position-icon {
  position: absolute;
  z-index: 999;
  left: 90px;
}
</style>
