<template>
  <div>
    <b-container class="no-gutters bg-white px-2 px-md-3 pb-2 pb-md-3">
      <b-table
        ref="tableCustomer"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(toggle)="row">
          <b-button variant="toggle" size="sm" @click="toggleDetails(row)">
            <font-awesome-icon
              :icon="row.detailsShowing ? 'chevron-down' : 'chevron-right'"
            />
          </b-button>
        </template>
        <template v-slot:cell(name)="data">
          <div class="justify-content-center text-left">
            <router-link :to="`/report/customer/${data.item.user_guid}`">
              <u> {{ data.item.name }}</u>
            </router-link>
          </div>
        </template>
        <template v-slot:cell(register_date)="data">
          <div>{{ data.item.register_date | moment($formatDateNew) }}</div>
          <div class="time-color">
            {{ data.item.register_date | moment("HH:mm:ss") }}
          </div>
        </template>
        <template v-slot:cell(new_friend_count)="data">
          <span>
            {{ data.item.new_friend_count | numeral("0,0") }}
          </span>
        </template>
        <template v-slot:cell(telephone)="data">
          <span>
            {{ convertTelePhone(data.item.telephone) }}
          </span>
        </template>
        <template v-slot:cell(total_spend)="data">
          <span>
            {{ data.item.total_spend | numeral("0,0.00") }}
          </span>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template #row-details="row">
          <div class="bg-white indent-customs">
            <TableCustomerListDetails
              :filter="row.item.details.filter"
              :fields="fieldsDetails"
              :items="row.item.details.items"
              :isBusy="row.item.details.isBusy"
              :rows="row.item.details.rows"
              :pageOptions="pageOptions"
              :currentPage="row.item.details.filter.page"
            />
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </b-container>
  </div>
</template>

<script>
import TableCustomerListDetails from "./TableCustomerListDetails";

export default {
  components: {
    TableCustomerListDetails,
  },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showing: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    fieldsDetails: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      toShow: this.showingTo,
      show: this.showing,
    };
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.show = 1;
      this.filter.take = value;
      this.toShow = value;
      this.filter.register_date = "0001-01-01";
      this.$emit("getPageOption", this.filter);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.show = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.show = value * this.filter.take - this.filter.take;
        this.toShow = this.show + this.filter.take;
      }
      this.$emit("getPage", this.filter);
    },
    async toggleDetails(row) {
      await row.toggleDetails();
      row.item.details.isBusy = true;
      if (!row.detailsShowing)
        this.$emit("getCustomerDetailsList", row, row.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.time-color {
  color: #b5b5b5;
}
.status-complete {
  color: #00bd00;
}
.status-void {
  color: #f9b115;
}
.status-cancel {
  color: #ff0000;
}
.btn-toggle {
  width: 30px;
  padding: 0;
}
.indent-customs {
  margin-left: 35px;
}
</style>
