<template>
  <div v-if="isLoading">
    <OtherLoading />
  </div>
  <div v-else>
    <div class="title-tabs">Background Page</div>
    <div class="bg-white px-3 pt-3">
      <b-row>
        <b-col xs sm="12" md lg xl="6">
          <UploadFile
            textFloat="Background Page"
            placeholder="Please Choose File"
            format="video"
            :fileName="form.background_page"
            v-model="form.background_page"
            name="thumbnail"
            text="*Please upload only .png, .jpg image size 390 x 769px"
            isRequired
            :accept="acceptType"
            :isValidate="v.form.background_page.$error"
            textValidate="Please Upload Background Image"
            v-on:onFileChange="
              (input) => onImageChange(input, 'background_page')
            "
            :v="v.form.background_page"
            :disabled="isDisable"
          />
          <!-- <PreviewBox
            v-on:delete="deleteImage"
            :disabled="isDisable"
            :showPreviewType="showPreviewType"
            :showPreview="showPreview"
            :isLoadingImage="isLoadingImage"
            :ratioType="16"
          /> -->
        </b-col>
      </b-row>
    </div>
    <div class="title-tabs">Content</div>
    <div class="bg-white px-3">
      <b-row>
        <b-col cols="6" class="mt-3">
          <InputTextArea
            textFloat="Share Label"
            placeholder="Max 255 Characters"
            type="text"
            v-model="form.share_label"
            name="share-label"
            isRequired
            rows="6"
            maxCharacter="255"
            :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 255);'"
            :v="v.form.share_label"
            :isValidate="v.form.share_label.$error"
          />
          <UploadFile
            textFloat="Image"
            placeholder="Please Choose File"
            format="video"
            :fileName="form.share_label_image"
            v-model="form.share_label_image"
            name="thumbnail"
            text="*Please upload only .png, .jpg image with ratio 1:1"
            isRequired
            :accept="acceptType"
            v-on:onFileChange="
              (input) => onImageChange(input, 'share_label_image')
            "
            :isValidate="v.form.share_label_image.$error"
            textValidate="Please Upload Image"
            :disabled="isDisable"
            :v="v.form.share_label_image"
          />
        </b-col>
        <b-col cols="6" class="my-3">
          <PreviewBox
            textFloat="Preview"
            :text="form.share_label"
            :bgImage="form.background_page"
            :image="form.share_label_image"
            textBtn="ต่อไป"
            tabIndex="1"
          />
        </b-col>
        <b-col cols="12">
          <div class="border-bottom"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="mt-3">
          <InputTextArea
            textFloat="Coupon Label"
            placeholder="Max 255 Characters"
            type="text"
            v-model="form.coupon_label"
            name="coupon-label"
            isRequired
            rows="6"
            maxCharacter="255"
            :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 255);'"
            :v="v.form.coupon_label"
            :isValidate="v.form.coupon_label.$error"
          />
          <UploadFile
            textFloat="Image"
            placeholder="Please Choose File"
            format="video"
            :fileName="form.coupon_label_image"
            v-model="form.coupon_label_image"
            name="thumbnail"
            text="*Please upload only .png, .jpg image with ratio 1:1"
            isRequired
            :accept="acceptType"
            v-on:onFileChange="
              (input) => onImageChange(input, 'coupon_label_image')
            "
            :isValidate="v.form.coupon_label_image.$error"
            textValidate="Please Upload Image"
            :disabled="isDisable"
            :v="v.form.coupon_label_image"
          />
        </b-col>
        <b-col cols="6" class="my-3">
          <PreviewBox
            textFloat="Preview"
            :text="form.coupon_label"
            :bgImage="form.background_page"
            :image="form.coupon_label_image"
            textBtn="ต่อไป"
            tabIndex="2"
            :privilegeCode="privilegeCode"
          />
        </b-col>
        <b-col cols="12">
          <div class="border-bottom"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="mt-3">
          <InputTextArea
            textFloat="Gift Label"
            placeholder="Max 255 Characters"
            type="text"
            v-model="form.gift_label"
            name="gift-label"
            isRequired
            rows="6"
            maxCharacter="255"
            :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 255);'"
            :v="v.form.gift_label"
            :isValidate="v.form.gift_label.$error"
          />
        </b-col>
        <b-col cols="6" class="my-3">
          <PreviewBox
            textFloat="Preview"
            :text="form.gift_label"
            :bgImage="form.background_page"
            :image="form.image_card.length ? form.image_card[0].image_url : ''"
            textBtn="ต่อไป"
            tabIndex="3"
            :imageList="form.image_card"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import PreviewBox from "./PreviewFriendGetFriend.vue";

export default {
  components: {
    OtherLoading,
    PreviewBox,
  },
  props: {
    id: {
      required: true,
      type: [String, Number],
    },
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    privilegeCode: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingImage: false,
      isDisable: false,
      coverImgType: 1,
      acceptType: "image/jpeg, image/png",
      showPreview: "",
      showPreviewType: 0,
      content: [
        {
          title: "Share Label",
          text: "",
          image_url: "",
        },
        {
          title: "Coupon Label",
          text: "",
          image_url: "",
        },
        {
          title: "Gift Label",
          text: "",
          image_url: "",
        },
      ],
    };
  },
  methods: {
    onImageChange(val, key) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.ratioMatch = true;
          this.setUpdataImage(reader.result, key);
        };
        img.src = reader.result;
      };
    },
    async setUpdataImage(base64, key) {
      if (this.ratioMatch === true) {
        var url = await this.saveImagetoDb(base64);
        this.showPreviewType = 0;
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = url;
        this.form[key] = url;
        // this.form.banner.is_video = 0;
      }
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
        is_video: false,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    deleteImage() {
      this.form.background_page = "";
      this.showPreview = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .date-picker .vdatetime-input {
  width: 100%;
  color: var(--primary-color);
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 15px;
}
</style>
