<template>
  <div v-if="isLoading">
    <OtherLoading />
  </div>
  <div v-else>
    <div class="title-tabs">Upload Banner</div>
    <div class="bg-white p-3">
      <b-row>
        <b-col xs sm="12" md lg xl="6">
          <UploadFile
            textFloat="Upload Banner"
            placeholder="Please Choose File"
            format="video"
            :fileName="form.banner"
            v-model="form.banner"
            name="thumbnail"
            text="*Please upload only .png, .jpg image less than 150 MB"
            isRequired
            :accept="acceptType"
            v-on:onFileChange="onImageChange"
            :isValidate="v.form.banner.$error"
            textValidate="Please Upload Banner Image"
            :v="v.form.banner"
            :disabled="isDisable"
          />
          <PreviewBox
            v-on:delete="deleteImage"
            :disabled="isDisable"
            :showPreviewType="showPreviewType"
            :showPreview="form.banner"
            :isLoadingImage="isLoadingImage"
            :ratioType="16"
          />
        </b-col>
      </b-row>
    </div>
    <div class="title-tabs">Invitation Message</div>
    <div class="bg-white px-3">
      <b-row>
        <b-col cols="12">
          <InputTextAreaVariable
            reference="textarea"
            class="mb-3"
            type="text"
            textFloat="Invite Message"
            placeholder=""
            name="text"
            rows="5"
            isRequired
            :isValidate="v.form.invitation_message.$error"
            :v="v.form.invitation_message"
            v-model="form.invitation_message"
            :variableItem="variables"
            :response="response"
          />
        </b-col>
      </b-row>
    </div>
    <div class="title-tabs">Card Message</div>
    <div class="bg-white px-3">
      <b-row>
        <b-col cols="12">
          <InputTextAreaVariable
            reference="textarea"
            class="mb-3"
            type="text"
            textFloat="Card Message"
            placeholder=""
            name="text"
            rows="5"
            isRequired
            :isValidate="v.form.card_message.$error"
            :v="v.form.card_message"
            v-model="form.card_message"
            :variableItem="cardVariables"
            :response="cardResponse"
          />
        </b-col>
      </b-row>
    </div>
    <div class="title-tabs">
      <b-row>
        <b-col cols="6">Image Message Card</b-col>
        <b-col cols="6" class="text-right">
          <button
            @click.prevent="showCollapse"
            class="btn-edit w-100 text-right"
          >
            Edit
          </button>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white px-3">
      <b-row>
        <b-col cols="12" class="p-3">
          <div class="thumbnailGroup">
            <MultipleImagesUnlimit
              textFloat="Image Message Card"
              @updateImageList="updateImageList"
              :dataList="form.image_card"
              :isValidate="v.form.image_card.$error"
              :v="v.form.image_card"
              v-on:deleteImagesList="deleteImageList"
              sortingDrag
              @sortOrderImage="sortOrderImageByDrag"
              :id="id"
              accept="image"
              isRequired
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <SidebarImageMessageCard
      ref="sidebarImageMessageCard"
      :imageList="form.image_card"
      :friendGetFriendId="id"
      @sortOrderImage="sortOrderImageByValue"
      accept="image"
    />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import PreviewBox from "@/components/PreviewBox.vue";
import InputTextAreaVariable from "@/components/inputs/InputTextAreaVariable";
import MultipleImagesUnlimit from "@/components/inputs/MultipleImagesUnlimit";
import SidebarImageMessageCard from "./SidebarImageMessageCard";

export default {
  components: {
    OtherLoading,
    PreviewBox,
    InputTextAreaVariable,
    MultipleImagesUnlimit,
    SidebarImageMessageCard,
  },
  props: {
    id: {
      required: true,
      type: [String, Number],
    },
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingImage: false,
      isDisable: false,
      coverImgType: 1,
      acceptType: "image/jpeg, image/png",
      showPreviewType: 0,
      variables: [
        { name: "First Name" },
        { name: "Last Name" },
        { name: "Referral Link" },
        { name: "Friend Point Reward" },
        { name: "Friend Privilege Name" },
      ],
      cardVariables: [
        { name: "First Name" },
        { name: "Last Name" },
        { name: "Friend Point Reward" },
        { name: "Friend Privilege Name" },
      ],
      deleteImageList: [],
      response: {
        value: this.form.invitation_message,
      },
      cardResponse: {
        value: this.form.card_message,
      },
    };
  },
  watch: {
    "response.value"(newValue, oldValue) {
      if (this.form.invitation_message != newValue) {
        this.form.invitation_message = newValue;
      }
    },
    "cardResponse.value"(newValue, oldValue) {
      if (this.form.card_message != newValue) {
        this.form.card_message = newValue;
      }
    },
  },
  async created() {},
  methods: {
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        if (val.type === "video/mp4") {
          var url = await this.saveImagetoDb(reader.result, true);
          this.showPreviewType = 1;
          this.isLoadingImage = false;
          this.isDisable = false;
          this.form.banner = url;
        } else {
          let img = new Image();
          img.onload = () => {
            this.ratioMatch = true;
            this.setUpdataImage(reader.result);
          };
          img.src = await reader.result;
        }
      };
    },
    async setUpdataImage(base64) {
      if (this.ratioMatch === true) {
        var url = await this.saveImagetoDb(base64, false);
        this.showPreviewType = 0;
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.banner = url;
      }
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    deleteImage() {
      this.form.banner = "";
    },
    deleteImagesList(id) {
      this.deleteImageList.push(id);
    },
    updateImageList(list) {
      this.form.image_card = list;
    },
    showCollapse() {
      this.$refs.sidebarImageMessageCard.show();
    },
    async sortOrderImageByDrag() {
      let image_card = this.form.image_card;
      image_card = await image_card.filter((el) => typeof el !== "undefined");
      await image_card.forEach((element, index) => {
        element.sort_order = index + 1;
      });
      this.form.image_card = image_card;
    },
    async sortOrderImageByValue() {
      await this.form.image_card.sort((a, b) => a.sort_order - b.sort_order);
    },
  },
};
</script>

<style lang="scss" scoped>
.black-color {
  color: #000;
}
.btn-edit {
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  font-weight: 600;
  padding: 0 !important;
}
</style>
