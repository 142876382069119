<template>
  <div>
    <b-container class="no-gutters bg-white p-2 p-md-3">
      <b-table
        ref="tableCustomer"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        :current-page="currentPage"
        :per-page="filter.take"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(name)="data">
          <div class="justify-content-center text-left">
            <router-link :to="`/report/customer/${data.item.user_guid}`">
              <u> {{ data.item.name }}</u>
            </router-link>
          </div>
        </template>
        <template v-slot:cell(register_date)="data">
          <div>{{ data.item.register_date | moment($formatDateNew) }}</div>
          <div class="time-color">
            {{ data.item.register_date | moment("HH:mm:ss") }}
          </div>
        </template>
        <template v-slot:cell(total_spend)="data">
          <span>
            {{ data.item.total_spend | numeral("0,0.00") }}
          </span>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
        @handleChangeTake="handleChangeTake"
      />
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    currentPage: {
      required: true,
      type: Number,
    },
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("getPageOption", this.filter);
    },
    pagination(value) {
      this.filter.page = value;

      this.$emit("getPageOption", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
.time-color {
  color: #b5b5b5;
}
.status-complete {
  color: #00bd00;
}
.status-void {
  color: #f9b115;
}
.status-cancel {
  color: #ff0000;
}
</style>
